import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),   
    zIndex:1,
    textAlign:"center",
    alignItems: 'center',
    bottom: "0",
    width:"100%"
  },
  text: {
    marginBottom: "0",

  }
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <footer  className={classes.root} 
    style={{
      position:props.position,
      paddingBottom: "2em",   
      zIndex:1,
      textAlign:"center",
      alignItems: 'center',
      bottom: "0",
      width:"100%"
    }}
    >
      <Typography
        variant="caption"
        className={classes.text}
      >
        Made by <b>@Yao</b> 
      </Typography>
    </footer>
  );
}
