/** @jsx jsx */
import { jsx } from 'theme-ui'
import Header from "./header"
import Footer from "./footer"
import {useState} from 'react'

const Layout = ({  children, fullScreen }) => {
  const [width, setWidth] = useState( (typeof window!== 'undefined' ) ? window.innerWidth: 0);
  let isMobile = (width <= 768);
  return (
    <div 
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height:'100%',
      minHeight: '100vh',
    }}
    >
      <Header style ={fullScreen?"dark":"light"}/>
      <main
      sx={{
        width: '100%',
        display: 'flex',
      }}>
      <div
        sx={{
          marginTop:fullScreen? "0":"10em",
          marginLeft:"0",
          marginRight:"0",
          position: 'relative',
          width:'100%',
          height:fullScreen? '100vh':'100%',
       }}>
          {children}
      </div>
    </main>
  
    <Footer 

     position={fullScreen? "absolute":"static"} 
      />

    </div>
  )
}

export default Layout
