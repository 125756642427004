import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import Zoom from "@material-ui/core/Zoom";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
// import resume from "./YaoResume.pdf";
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  menuButton: {
    marginRight: theme.spacing(4)
  },
  title: {
    flexGrow: 1,
    paddingLeft:theme.spacing(2),
    fontSize: "4em",
    color:"black",
    textDecoration: "none", 
    fontWeight: "600",
  },
  toolbar:{
    display:"flex", 
  },

  list: {
    width: 250
  },

  underlineNone: {
    paddingRight:theme.spacing(2),
    textDecoration: "none", 
    fontSize:"0.7em", 
    color: "black",
    fontWeight: "200",
  }
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func
};

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func
};


function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func
};

export default function Header(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>

        <ListItem className={classes.nonested} style={{ paddingLeft: "4px"}}>
          <Button            
            onClick={toggleDrawer(side, false)}
          >
            <CloseIcon
             style={{ alignSelf:"center" }}
            />
            <ListItemText 
              primary="Close" 
            />
          </Button>
        </ListItem>
        <Link to={"/about"} className={classes.underlineNone}>
          <ListItem
            button
            className={classes.nested}
            onClick={toggleDrawer(side, false)}
          >
            <ListItemText
              primary="About Me" 
            />
          </ListItem>
        </Link>

        <Link
          activeclass="active"
          to="/"
          className={classes.underlineNone}
          spy={"true"}
          smooth={"true"}
          offset={0}
          duration={500}
        >
        
          <ListItem
            button
            className={classes.nonested}
            onClick={toggleDrawer(side, false)}
          >
          <ListItemText  
            primary="Projects" 
          />
          </ListItem>
        </Link>

        <a href={"./YaoResume.pdf"} className={classes.underlineNone} >
          <ListItem button className={classes.nonested} >
            <ListItemText 
              primary="Resume" 
            />
          </ListItem>
        </a>
      </List>
    </div>
  );
   let colorstring =   props.style==="dark"? "rgba(249, 249, 249, 0)":"rgba(249, 249, 249, 1)"

  return (
    <React.Fragment >
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          
          style={{ background: colorstring }}
         
         >

          <Toolbar
           className={classes.toolbar}
          >
            <Hidden xsDown>

              <Typography href="/"  style={{ alignSelf:"flex-end" }} className={classes.title}>
                Yao
              </Typography>

              <Link style={{ alignSelf:"flex-end" }}className={classes.underlineNone}
                activeclass="active"
                to={"/"}
                spy={"true"}
                smooth={"true"}
                offset={0}
                duration={500}
              >
                <Button
                  className="coverButton"
                  size="large"
                  color="inherit"
                  style={{ textTransform: "none",fontSize:"1em"  }}
                >
                  Projects
                </Button>
              </Link>

              <Link style={{ alignSelf:"flex-end" }}to={"/about"} className={classes.underlineNone}>
                <Button
                  className="coverButton"
                  size="large"
                  color="inherit"
                  style={{ textTransform: "none",fontSize:"1em" }}
                >
                  About Me
                </Button>
              </Link>

              <a style={{ alignSelf:"flex-end" }}href={"./YaoResume.pdf"} className={classes.underlineNone}>
              <Button
              
                size="large"
                color="inherit"
                style={{ textTransform: "none",fontSize:"1em"  }}
              >
                Resume
              </Button>
              </a>
            </Hidden>

            <Hidden smUp>

              <IconButton
                style={{ alignSelf:"center" }}
                onClick={toggleDrawer("left", true)}
                edge="start"
                className={classes.menuButton}
                color= "default"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
              <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
                {sideList("left")}
              </Drawer>

              <Typography variant="h6" className={classes.title}>
                Yao
              </Typography>
            </Hidden>
          </Toolbar>
        </AppBar>


      </ElevationScroll>
      <div id="back-to-top-anchor" />
    </React.Fragment>
  );
}

